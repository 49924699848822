body.express-registration {
  /* Fix navigation header - prevent scroll-triggered movement */
  &, &.fixed-nav {
    padding-top: 62px; /* Exact nav height */

    #primary-nav {
      position: fixed !important;
      top: 0 !important;
      transform: none !important;
      height: 62px;

      /* Ensure it doesn't move during transitions */
      transition: none !important;
      animation: none !important;
    }
  }

  /* Hide specific navigation elements */
  #primary-nav .navbar-header .navbar-content .control .navbar-toggle,
  #primary-nav .navbar-header .user-controls ul li a {
    visibility: hidden;
  }

  /* Keep logo visible but prevent navigation */
  #primary-nav {
    .navbar-header {
      .navbar-brand {
        pointer-events: none; /* Prevent clicking */
        cursor: default;
      }
    }

    /* Create a transparent overlay over the entire nav */
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10; /* Above nav elements but below modals */
      pointer-events: auto; /* Block all clicks */
    }
  }

  /* hide elements and prevent clicks on footer */
  #global-footer {
    .brand-links, .content-links, .quick-links {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10; /* Above nav elements but below modals */
      pointer-events: auto; /* Block all clicks */
    }
  }

  /* Disable all other navigation elements */
  .dg-navigation,
  .user-login-control,
  .nav-mobile-dropdown {
    pointer-events: none;
  }
}